import React from 'react';

export default class Chapter extends React.Component {
    handleChange = (e) => {
        let path = `/${this.props.lang}/page/${this.getFirstPage(e.target.value)}`
        window.location.href = path
    }

    renderSelect = () => {
        if (this.props.current === undefined || this.props.chapters === undefined) {
            return
        }
        var options = []
        this.props.chapters.forEach(chapter => {
            options.push(<option class="chapter-option" value={chapter.id}>{chapter.title}</option>)
        })
        return <select class="chapter-select-standard" onChange={this.handleChange} value={this.props.current}>
                {options}
            </select>
    }

    renderSmallSelect = () => {
        if (this.props.current === undefined || this.props.chapters === undefined) {
            return
        }
        var options = []
        this.props.chapters.forEach(chapter => {
            options.push(<option class="chapter-option" value={chapter.id}>{this.getChapterSmallTitle(chapter)}</option>)
        })

        return <select class="chapter-select-small" onChange={this.handleChange} value={this.props.current}>
            {options}
        </select>
    }

    getChapterSmallTitle = (chapter) => {
        if (this.props.lang === "es") {
            return "Episodio " + chapter.id
        }

        if (this.props.lang === "en") {
            return "Episode " + chapter.id
        }

        return "エピソード " + chapter.id
    }

    getFirstPage = (chapterId) => {
        var fp 
        this.props.chapters.forEach(chapter => {
            if (chapter.id === chapterId) {
                fp = chapter.first_page
                return
            }
        })

        return fp
    }

    render = () => {
        return <div class="chapter-select">
            {this.renderSelect()}
            {this.renderSmallSelect()}
        </div>
      }
}