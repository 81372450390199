import React from 'react';
import ReactImageAppear from 'react-image-appear';

export default class Page extends React.Component {
  previousButton() {
    if (this.props.page.img === undefined) {
      return
    }

    if (!this.props.page.previous && this.props.page.previous !== 0) {
      return         
    }
    
    return <div id="previous">
      <a href={`/${this.props.lang}/page/${this.props.page.previous}`}>
        <img src="/img/arrow_left.png" alt="left"/>
      </a>
    </div>
  }

  nextButton() {
    if (this.props.page.img === undefined) {
      return
    }

    if (!this.props.page.next) {
      return
    }
    
    return <div id="next">
      <a href={`/${this.props.lang}/page/${this.props.page.next}`}>
        <img src="/img/arrow_right.png" alt="right"/>
      </a>
    </div>
  }
  
  page() {
    if (!this.props.page.next) {
      return this.image()
    }

    return <a href={`/${this.props.lang}/page/${this.props.page.next}`}>
      {this.image()}
    </a>
  }

  image() {
    if (this.props.page.img === undefined) {
      return
    }

    return <ReactImageAppear 
        src={this.props.page.img}
        animation="fadeIn"
        animationDuration="1s"
        showLoader={false}
        placeholder="/img/loading.gif"
        placeholderClass="loading"
    />
  }

  render() {
      return (
          <div id="main" className="page">
              <div id="controls-top" className="controls">
                  {this.previousButton()}
                  {this.nextButton()}
              </div>
              <div id="page">
                  {this.page()}
              </div>
              <div id="controls-bottom" className="controls">
                  {this.previousButton()}
                  {this.nextButton()}
              </div>
          </div>
      );
  }
}