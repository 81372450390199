import React from 'react';
import { BrowserRouter as Router, Redirect } from 'react-router-dom'
import Chapter from './Chapter'
import Page from './Page'

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: {
      },
    }
    this.redirect = false
    this.lang = "jp"
    this.current = 0
    this.setPageData(window.location.pathname)
  }

  setPageData = (path) => {
    let regex = /^\/(jp|es|en).*$/,
    match;
    
    match = regex.exec(path);

    if (match == null || match.length <= 0) {
      this.redirect = true
      return
    }
    this.lang = match[1]

    regex = /^\/(?:jp|es|en)\/page\/(\d+)$/
    
    match = regex.exec(path);

    if (match == null || match.length <= 0) {
      this.redirect = true
      return
    }
    this.current = match[1]
  } 

  renderRedirect = () => {
    if (this.redirect) {
      return <Router><Redirect to={`/${this.lang}/page/0`}/></Router>
    }
  }

  componentDidMount() {
    fetch('https://api.takakochan.com/page/'+this.current, {
      headers: {
        "X-Lang": this.lang,
      },
    })
    .then(res => res.json())
    .then((data) => {
      this.setState({ page: data })
    })
    .catch(console.log)

    fetch('https://api.takakochan.com/chapters', {
      headers: {
        "X-Lang": this.lang,
      },
    })
    .then(res => res.json())
    .then((data) => {
      this.setState({ chapters: data })
    })
    .catch(console.log)
  }

  render() {
    console.log(this.state.page)
    return (
      <div id="content">
        {this.renderRedirect()}
        <div id="main-title" className="vertically-centered-content">
          <div id="lang-selector">
            <a id="jp" href={`/jp/page/${this.current}`}><img alt="jp" src="/img/jp.png"/></a>
            <a id="es" href={`/es/page/${this.current}`}><img alt="es" src="/img/es.png"/></a>
            <a id="en" href={`/en/page/${this.current}`}><img alt="en" src="/img/en.png"/></a>
          </div>
          <Chapter chapters={this.state.chapters} current={this.state.page.chapter} lang={this.lang}/>
          <div id="logo">
            <a href={`/${this.lang}/`}>
              <img src="/img/banner.png" alt="Takako-chan"/>
            </a>
          </div>
          <div id="social" className="vertically-centered-content">
            <a href="https://www.instagram.com/takakoochan/">
              <img alt="Instagram" src="/img/instagram.png"/><p>@takakoochan</p>
            </a>
          </div>
          <br/>
        </div>
        <Page current={this.current} page={this.state.page} lang={this.lang}/>
      </div>
    );
  }
}

export default App;
